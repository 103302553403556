import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-353cae76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-left-side" }
const _hoisted_2 = { class: "folder-icon mr-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "folder-title-info" }
const _hoisted_5 = { class: "folder-title long-name mr-1" }
const _hoisted_6 = { class: "last-change-date" }
const _hoisted_7 = { class: "mr-1" }
const _hoisted_8 = { class: "content-right-side" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "option-menu-wrapper" }
const _hoisted_11 = { class: "menu-options" }
const _hoisted_12 = { class: "option" }
const _hoisted_13 = { class: "option" }
const _hoisted_14 = { class: "option" }
const _hoisted_15 = { class: "option delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_menu_option_dropdown = _resolveComponent("menu-option-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container-folder-item", {
      'bg-grey': (_ctx.showMenuOptionId == _ctx.folder.id || _ctx.isHover) && !_ctx.isSelected,
      'bg-checked': _ctx.isSelected,
    }]),
    onMouseleave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isHover = false)),
    onMouseover: _cache[11] || (_cache[11] = ($event: any) => (_ctx.isHover = true))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ((_ctx.isHover && !_ctx.isMobile) || _ctx.isSelected)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "checkbox",
              class: "input-checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSelected) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSelectFolder(_ctx.folder)))
            }, null, 544)), [
              [_vModelCheckbox, _ctx.isSelected]
            ])
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: require('icons/primary-folder.svg'),
              alt: "folder",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClickIcon(_ctx.folder)))
            }, null, 8, _hoisted_3))
      ]),
      _createElementVNode("div", {
        class: "folder-info",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleAccessFolder && _ctx.handleAccessFolder(...args)))
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.folder.name), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.folder.updatedAt
                ? _ctx.$t("template.lastUpdate") + ` ${_ctx.formatDate(_ctx.folder.updatedAt, "YYYY/MM/DD")}`
                : ""), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_TextButton, {
        class: "folder-person-access",
        icon: require('icons/primary-access-outline.svg'),
        text: _ctx.folder.countShare.toString(),
        "disabled-icon": require('icons/primary-access-outline-grey.svg'),
        enabled: _ctx.hasPermission,
        onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('onEditAccessFolder', _ctx.folder)))
      }, null, 8, ["icon", "text", "disabled-icon", "enabled"]),
      _createElementVNode("div", {
        class: _normalizeClass(["icon", { selected: _ctx.showMenuOptionId == _ctx.folder.id }]),
        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleShowMenuOption(_ctx.folder.id)))
      }, [
        _createElementVNode("img", {
          src: require('icons/more.svg'),
          alt: "more",
          disabled: ""
        }, null, 8, _hoisted_9),
        _withDirectives(_createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_menu_option_dropdown, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_TextButton, {
                    icon: require('icons/edit.svg'),
                    "disabled-icon": require('icons/edit-grey.svg'),
                    text: _ctx.$t('template.renameFolder'),
                    enabled: _ctx.folder.canUpdate,
                    onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('onRenameFolder', _ctx.folder)))
                  }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_TextButton, {
                    icon: require('icons/primary-access-outline.svg'),
                    "disabled-icon": 
                    require('icons/primary-access-outline-grey.svg')
                  ,
                    text: _ctx.$t('template.editPermission'),
                    enabled: _ctx.hasPermission,
                    onOnClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('onEditAccessFolder', _ctx.folder)))
                  }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_TextButton, {
                    icon: require('icons/move_icon.svg'),
                    "disabled-icon": require('icons/move_icon_grey.svg'),
                    text: _ctx.$t('template.moveOption'),
                    enabled: _ctx.folder.canUpdate,
                    onOnClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('onMoveFolder', _ctx.folder)))
                  }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_TextButton, {
                    icon: require('icons/delete.svg'),
                    "disabled-icon": require('icons/delete-grey.svg'),
                    text: _ctx.$t('contact.delete'),
                    enabled: _ctx.folder.canDelete,
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('onDeleteFolder', _ctx.folder)))
                  }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                ])
              ]),
              _: 1
            })
          ])
        ], 512), [
          [_vShow, _ctx.showMenuOptionId == _ctx.folder.id]
        ])
      ], 2)
    ])
  ], 34))
}