import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-269bf7a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-left-side" }
const _hoisted_2 = { class: "contract-icon mr-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "contract-info" }
const _hoisted_7 = { class: "contract-title-info" }
const _hoisted_8 = {
  key: 0,
  class: "last-change-date"
}
const _hoisted_9 = { class: "mr-1" }
const _hoisted_10 = { class: "content-right-side" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "option-menu-wrapper" }
const _hoisted_13 = { class: "menu-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_menu_option_dropdown = _resolveComponent("menu-option-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container-contract-item", {
      'bg-grey': (_ctx.showMenuOptionId == _ctx.contract.id || _ctx.isHover) && !_ctx.isSelected,
      'bg-checked': _ctx.isSelected,
    }]),
    onMouseleave: _cache[14] || (_cache[14] = ($event: any) => (_ctx.isHover = false)),
    onMouseover: _cache[15] || (_cache[15] = ($event: any) => (_ctx.isHover = true))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isBuiltInTemplate)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('icons/digitalsign-template.svg'),
              alt: "template icon"
            }, null, 8, _hoisted_3))
          : ((_ctx.isHover && !_ctx.isMobile) || _ctx.isSelected)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                type: "checkbox",
                class: "input-checkbox",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSelected) = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSelectContract(_ctx.contract)))
              }, null, 544)), [
                [_vModelCheckbox, _ctx.isSelected]
              ])
            : (_ctx.contract.isDocxTemplate)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: require('icons/template-blue.svg'),
                  alt: "template icon",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClickIcon(_ctx.contract)))
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("img", {
                  key: 3,
                  src: require('icons/primary-template.svg'),
                  alt: "template icon",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleClickIcon(_ctx.contract)))
                }, null, 8, _hoisted_5))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", {
            class: _normalizeClass(["contract-title mr-1", { 'enabled-click': _ctx.currentTabIndex == 0 }]),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickTitle(_ctx.contract.id)))
          }, _toDisplayString(_ctx.contract.title), 3)
        ]),
        (!_ctx.isBuiltInTemplate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.contract.updatedAt
                ? `${_ctx.$t("template.lastUpdate")} ${_ctx.formatDate(
                    _ctx.contract.updatedAt,
                    "YYYY/MM/DD"
                  )}`
                : ""), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (!_ctx.contract.isDocxTemplate || _ctx.isBuiltInTemplate)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["contract-btn-sign__wrapper", { 'mr-1': !_ctx.isBuiltInTemplate }])
          }, [
            _createVNode(_component_FlatButton, {
              text: _ctx.$t('template.makeContract'),
              class: "contract-btn-sign",
              onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.createContractFromTemplate(_ctx.contract.id)))
            }, null, 8, ["text"])
          ], 2))
        : _createCommentVNode("", true),
      (!_ctx.isBuiltInTemplate)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_TextButton, {
              class: "folder-person-access mr-1",
              icon: require('icons/primary-access-outline.svg'),
              text: _ctx.contract.countShare.toString(),
              "disabled-icon": require('icons/primary-access-outline-grey.svg'),
              enabled: _ctx.isPermitted,
              onOnClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('onEditAccess', _ctx.contract)))
            }, null, 8, ["icon", "text", "disabled-icon", "enabled"]),
            _createElementVNode("div", {
              class: _normalizeClass(["icon", { selected: _ctx.showMenuOptionId == _ctx.contract.id }]),
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.handleShowMenuOption(_ctx.contract.id)))
            }, [
              _createElementVNode("img", {
                src: require('icons/more.svg'),
                alt: "more",
                disabled: ""
              }, null, 8, _hoisted_11),
              _withDirectives(_createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_menu_option_dropdown, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_FlatButton, {
                        text: _ctx.$t('template.makeContract'),
                        class: "contract-create-btn mobile",
                        onOnClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.createContractFromTemplate(_ctx.contract.id)))
                      }, null, 8, ["text"]),
                      _createVNode(_component_TextButton, {
                        icon: require('icons/edit.svg'),
                        "disabled-icon": require('icons/edit-grey.svg'),
                        text: _ctx.$t('folder.edit'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('onEditContract', _ctx.contract.id))),
                        class: "option"
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"]),
                      _createVNode(_component_TextButton, {
                        class: "option",
                        icon: require('icons/primary-access-outline.svg'),
                        "disabled-icon": 
                    require('icons/primary-access-outline-grey.svg')
                  ,
                        text: _ctx.$t('folder.editAccess'),
                        enabled: _ctx.isPermitted,
                        onOnClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('onEditAccess', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"]),
                      _createVNode(_component_TextButton, {
                        class: "option",
                        icon: require('icons/move_icon.svg'),
                        "disabled-icon": require('icons/move_icon_grey.svg'),
                        text: _ctx.$t('folder.moveFolder'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('onMoveContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"]),
                      _createVNode(_component_TextButton, {
                        class: "option",
                        icon: require('icons/download.svg'),
                        "disabled-icon": require('icons/download-grey.svg'),
                        text: _ctx.$t('folder.download'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.download(_ctx.getFilePathFromUrl(_ctx.contract.zipFilePath))))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"]),
                      _createVNode(_component_TextButton, {
                        class: "option delete",
                        icon: require('icons/delete.svg'),
                        "disabled-icon": require('icons/delete-grey.svg'),
                        text: _ctx.$t('contact.delete'),
                        enabled: _ctx.contract.canDelete,
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('onDeleteContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _: 1
                  })
                ])
              ], 512), [
                [_vShow, _ctx.showMenuOptionId == _ctx.contract.id]
              ])
            ], 2)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 34))
}