
import { defineComponent, watch, reactive, toRefs, inject } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { formatDate } from "@/lib/utility/common";
import { mapMutations } from "vuex";
import { useI18n } from "vue-i18n";
import { hasPermission } from "@/lib/utility/permission";

export default defineComponent({
  name: "TemplateFolderItem",
  components: {
    TextButton,
    MenuOptionDropdown,
  },
  props: {
    folder: { type: Object, required: true },
    showMenuOptionId: { type: String, default: "" },
    selectedFolderList: { type: Array },
    folderPath: { type: String, default: "" },
  },
  emits: [
    "onSelect",
    "onSelectFile",
    "onRenameFolder",
    "onEditAccessFolder",
    "onMoveFolder",
    "onDeleteFolder",
    "onShowMenuOption",
  ],
  setup(props) {
    const state = reactive({
      isHover: false,
      isSelected: false,
      hasPermission: hasPermission(props.folder.role)
    });

    const { t } = useI18n();
    const isMobile = inject("isMobile");

    watch(
      () => props.selectedFolderList,
      (selectedFolderList) => {
        state.isSelected =
          selectedFolderList?.some(
            (item: any) => item.id === props.folder.id
          ) ?? false;
      },
      {
        immediate: true,
      }
    );

    return {
      ...toRefs(state),
      formatDate,
      isMobile,
    };
  },
  methods: {
    ...mapMutations("notification", ["showErrorNotification"]),
    handleSelectFolder(item: any) {
      item.isFolder = true;
      this.isHover = false;
      this.$emit("onSelect", item, this.isSelected);
    },
    handleAccessFolder() {
      if (this.folder.role === 0) {
        this.showErrorNotification(
          this.$t("notification.accessPermissionError")
        );
      } else {
        this.$router.push({
          name: "TemplateList",
          query: { folderId: this.folder.id, folderTitle: this.folder.name },
          params: { folderPath: this.folderPath },
        });
      }
    },

    handleClickIcon(item: any) {
      this.isSelected = true;
      this.handleSelectFolder(item);
    },
    handleShowMenuOption(id: string) {
      this.$emit("onShowMenuOption", id);
    },
  },
});
