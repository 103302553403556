
import {
  defineComponent,
  inject,
  reactive,
  toRefs,
  onMounted,
  provide,
  computed,
  watch,
} from "vue";
import { mapMutations, useStore } from "vuex";
import { formatDate, getListFolderFromPath } from "@/lib/utility/common";
import { useRoute } from "vue-router";
import {
  RepositoryFactory,
  FolderRepository,
  ContractRepository,
} from "@/lib/https";
import { useI18n } from "vue-i18n";
import { SimpleBuiltInTemplate } from "@/models/TemplateAndContract";
import useContractFromTemplate from "@/lib/compositional-logic/useContractFromTemplate";
import {
  Folder,
  FolderI,
  FolderPaginationI,
  Contract,
  ContractI,
} from "@/models/Folder";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FolderRenamePopup from "@/components/popups/folder/FolderRenamePopup.vue";
import FolderAddPopup from "@/components/popups/folder/FolderAddPopup.vue";
import FolderEditMemberPopup from "@/components/popups/folder/FolderEditMemberPopup.vue";
import ContractEditMemberPopup from "@/components/popups/folder/ContractEditMemberPopup.vue";
import FolderContractMovePopup from "@/components/popups/folder/FolderContractMovePopup.vue";
import ConfirmDeletePopup from "@/components/popups/folder/ConfirmDeletePopup.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import LoadMore from "@/components/atomics/LoadMore.vue";
import FolderItem from "@/views/pages/template/TemplateFolderItem.vue";
import TemplateItem from "@/components/parts/TemplateItem.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import Tabs from "@/components/atomics/Tabs/Tabs.vue";
import Tooltip from "@/components/atomics/ToolTip.vue";
import useFolderContractHandler from "@/lib/compositional-logic/useFolderContractHandler";
import { hasPermission, ReadWritePermissions } from "@/lib/utility/permission";

enum DeletePopupList {
  DELETE_FOLDER,
  DELETE_CONTRACT,
  DELETE_MULTIPLE,
  DELETE_TEMPLATE,
}

enum RenamePopupList {
  RENAME_FOLDER,
  RENAME_CONTRACT,
}

enum MovePopupList {
  MOVE_FOLDER,
  MOVE_CONTRACT,
  MOVE_MULTIPLE,
}

const FolderPermissions = ReadWritePermissions

export default defineComponent({
  name: "TemplateList",
  components: {
    AfterLoginPageLayout,
    FlatButton,
    TextButton,
    ValidationTextField,
    LoadMore,
    FolderRenamePopup,
    FolderAddPopup,
    FolderEditMemberPopup,
    ContractEditMemberPopup,
    FolderContractMovePopup,
    ConfirmDeletePopup,
    FolderItem,
    TemplateItem,
    BreadScrum,
    Tabs,
    Tooltip,
  },
  props: {
    folderPath: { type: String, default: "" },
    isDeleteContractSuccerss: { type: String, default: "false" },
    isEditTemplateSuccess: { type: String, default: "false" },
    id: { type: String, default: "" },
  },
  setup(props) {
    const { t } = useI18n();
    const isMobile = inject("isMobile");
    const route = useRoute();
    const { folderId } = route.query;
    const store = useStore();
    const ROOT_BREADSCRUM_NAME = t("template.custom");
    const state = reactive({
      currentTabIndex: 0,
      foldersData: [] as FolderI[],
      foldersPagination: {} as FolderPaginationI,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contractsData: [] as any[],
      contractsPagination: {} as any,
      builtinTemplateList: [] as SimpleBuiltInTemplate[],
      builtinTemplatePagination: {} as any,
      selectedFolders: [] as FolderI[],
      selectedTemplates: [] as ContractI[],
      seachFolderContractKeyword: "",
      searchBuiltinKeyword: "",
      seachFolderContractKeywordTextField: "",
      searchBuiltinKeywordTextField: "",
      folderId: folderId as string,
      renameFolderItem: new Folder(),
      editAccessFolderItem: new Folder(),
      editAccessContractItem: new Contract(),
      moveFolderItem: new Folder(),
      moveContractItem: new Contract(),
      deleteFolderItem: new Folder(),
      deleteContractItem: new Contract(),
      isfirstPageContract: false,
      showMenuOptionId: "",
      breadScrumPaths: [
        {
          id: "",
          text: ROOT_BREADSCRUM_NAME,
          to: "/template",
        },
      ] as {
        id?: string;
        text: string;
        to: string;
      }[],
      showTooltipAccess: false,
      // pagination state
      pageFolder: 1,
      pageContract: 0,
      pageBuiltin: 1,
      totalFolder: 0,
      totalContract: 0,
      totalBuiltin: 0,
      isLoading: false,
      confirmSelectedPopup: DeletePopupList.DELETE_FOLDER,
      renameSelectedPopup: RenamePopupList.RENAME_FOLDER,
      moveSelectedPopup: MovePopupList.MOVE_FOLDER,
      currentFolderPath: "",
      currentFolderName: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tabTags: [] as Array<any>,
      isShiftPress: false,
      draggedItem: "",
      dragEnterItem: "",
    });

    const showSuccessNotification = (text: string) =>
      store.commit("notification/showSuccessNotification", text);
    const showErrorNotification = (text: string) =>
      store.commit("notification/showErrorNotification", text);

    const totalItems = computed(() => state.totalFolder + state.totalContract);

    state.tabTags = [
      { name: `${t("template.custom")} (${totalItems.value})` },
      { name: `${t("template.buildin")} (${state.totalBuiltin})` },
    ];

    watch(
      () => state.totalBuiltin,
      () => {
        state.tabTags[1].name = `${t("template.buildin")} (${
          state.totalBuiltin
        })`;
      },
      { immediate: true }
    );

    watch(
      () => totalItems.value,
      (newVal) => {
        state.tabTags[0].name = `${t("template.custom")} (${newVal})`;
        // const lastBreadScrum = state.breadScrumPaths[
        //   state.breadScrumPaths.length - 1
        // ] as any;
        // const listFolderPath = getListFolderFromPath(state.currentFolderPath);
        // const lastPathElement = listFolderPath.pop() as any;
        // if (state.breadScrumPaths.length > 1) {
        //   state.breadScrumPaths[0].text = ROOT_BREADSCRUM_NAME;
        //   lastBreadScrum.text = `${lastPathElement?.name} ${
        //     newVal > 0 ? "(" + newVal + ")" : ""
        //   }`;
        // } else {
        //   lastBreadScrum.text = `${ROOT_BREADSCRUM_NAME} ${
        //     newVal > 0 ? "(" + newVal + ")" : ""
        //   }`;
        // }
      },
      { immediate: true }
    );

    provide("FolderPermissions", FolderPermissions);
    provide("DeletePopupList", DeletePopupList);
    provide("RenamePopupList", RenamePopupList);
    provide("MovePopupList", MovePopupList);

    const { getListFolderContract } =
      RepositoryFactory.getRepository<FolderRepository>(FolderRepository);

    const { getBuiltinTemplate } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

    const { onDragItemStart, onDragItemEnd, onDragItemEnter, onDropItem } =
      useFolderContractHandler(state);

    const PAGE_SIZE_DEFAULT = 20;

    const loadFolderContract = async (condition = {}) => {
      const baseCondition = {
        page: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        folderId: folderId as string,
        isTemplateFolder: true,
        keyword: state.seachFolderContractKeyword,
        ...condition,
      };
      const response = await getListFolderContract(baseCondition);
      return response;
    };

    const loadBuiltinTemplate = async (condition = {}) => {
      const baseCondition = {
        page: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        folderId: folderId as string,
        keyword: state.searchBuiltinKeyword,
        ...condition,
      };
      const response = await getBuiltinTemplate(baseCondition);
      return response;
    };

    const reloadContractFolder = async ({
      reloadFolder = false,
      reloadContract = false,
    }) => {
      const contractFolderPromise: Promise<any>[] = [];
      if (reloadFolder) {
        contractFolderPromise.push(
          loadFolderContract({
            page: 1,
            pageSize: state.pageFolder * PAGE_SIZE_DEFAULT,
          })
        );
      }
      if (reloadContract) {
        contractFolderPromise.push(
          loadFolderContract({
            page: 1,
            pageSize: state.pageContract * PAGE_SIZE_DEFAULT,
          })
        );
      }
      const response = await Promise.all(contractFolderPromise);
      state.totalFolder = response[0].folders.pagination.totalRecords ?? 0;
      state.totalContract = response[0].contracts.pagination.totalRecords ?? 0;
      if (reloadFolder && reloadContract) {
        state.foldersData = response[0].folders.data as FolderI[];
        state.contractsData = response[1].contracts.data;
      } else {
        if (reloadFolder) {
          state.foldersData = response[0].folders.data;
        } else if (reloadContract) {
          state.contractsData = response[0].contracts.data;
        }
      }
    };


    const renderFolders = (folders: any) => {
      for (const index in folders.data) {
        const isContain = state.foldersData.some(
          (folder: FolderI) => folder.id === folders.data[index].id
        );
        if (
          !isContain &&
          folders.data[index].id != "received-folder"
        ) {
          state.foldersData.push(folders.data[index]);
        }
      }
    }
    
    const renderContracts = (contracts: any) => {
      for (const index in contracts.data) {
        const isContain = state.contractsData.some(
          (contract: ContractI) => contract.id === contracts.data[index].id
        );
        if (!isContain) {
          state.contractsData.push(contracts.data[index]);
        }
      }
    }

    const renderBuiltin = (builtin: any) => {
      for (const index in builtin) {
        const isContain = state.builtinTemplateList.some(
          (builtinItem: SimpleBuiltInTemplate) => builtinItem.id === builtin[index].id
        );
        if (!isContain) {
          state.builtinTemplateList.push(builtin[index]);
        }
      }
    }

    const loadMore = async (type: string, onScroll: boolean) => {
      if (state.isLoading) return
      if (type == "folder-contract") {
        const totalPageFolder = Math.ceil(state.totalFolder / PAGE_SIZE_DEFAULT)
        const totalPageContract = Math.ceil(state.totalContract / PAGE_SIZE_DEFAULT)
        const fBookmark = state.foldersPagination.bookmark as any
        const cBookmark = state.contractsPagination.bookmark as any
        
        if (state.pageFolder < totalPageFolder) {
          // Load folder item
          state.isLoading = true;
          state.pageFolder++
          const {folders} = await loadFolderContract({
            page: state.pageFolder,
            bookmark: fBookmark
          });
          renderFolders(folders)
          state.totalFolder = folders.pagination.totalRecords ?? 0;
          state.foldersPagination.bookmark = folders.pagination.bookmark || 0;
          state.isLoading = false;
        } else if (state.pageFolder >= totalPageFolder && state.pageContract < totalPageContract) {
          // Load contract item
          state.isLoading = true;
          state.pageContract++
          const { contracts } = await loadFolderContract({
            page: state.pageContract,
            bookmark: cBookmark
          });
          renderContracts(contracts)
          state.totalContract = contracts.pagination.totalRecords ?? 0;
          state.contractsPagination.bookmark = contracts.pagination.bookmark || 0;
          state.isLoading = false;
        } else if(state.pageFolder >= totalPageFolder && state.pageContract >= totalPageContract) {
          // Load more bookmark item when in last page
          const { folders, contracts } = await loadFolderContract({
            page: state.pageContract + 1,
            bookmark: fBookmark,
          });
          state.totalFolder = folders.pagination.totalRecords ?? 0;
          state.totalContract = contracts.pagination.totalRecords ?? 0;

          if(state.foldersData.length >= state.totalFolder && 
              state.contractsData.length >= state.totalContract && 
              !onScroll) {
            showErrorNotification(t("errors.errorNoNewRecord"));
          } else {
            if(state.foldersData.length < state.totalFolder) {
              renderFolders(folders)
            }
            if(state.contractsData.length < state.totalContract) {
              renderContracts(contracts)
            } 
          }
          state.foldersPagination.bookmark =
            folders.pagination.bookmark || 0;
          state.contractsPagination.bookmark =
            contracts.pagination.bookmark || 0;
          state.isLoading = false;  
        }
      } else if (type == "builtin") {
        // Load builtin template
        state.isLoading = true;
        const totalPageBuilin = Math.ceil(state.totalBuiltin / PAGE_SIZE_DEFAULT)
        const bBookmark = state.builtinTemplatePagination.bookmark as any
        let res = await loadBuiltinTemplate({
            page: state.pageBuiltin < totalPageBuilin ? ++state.pageBuiltin: state.pageBuiltin + 1,
            bookmark: bBookmark,
        });
        const {data, pagination} = (res as any).data || {}
        if (state.builtinTemplateList.length >= pagination.totalRecords && !onScroll) {
          showErrorNotification(t("errors.errorNoNewRecord"));
        } else {
          renderBuiltin(data)
        }
        state.totalBuiltin = pagination.totalRecords ?? 0;
        state.builtinTemplatePagination.bookmark = pagination.bookmark || 0;
        state.isLoading = false;
      }
    };

    const getInitListFolderContract = async () => {
      const response = await loadFolderContract();
      if (response) {
        state.foldersData = response.folders.data;
        state.foldersPagination = response.folders.pagination;
        state.totalFolder = response.folders.pagination.totalRecords ?? 0;
        state.totalContract = response.contracts.pagination.totalRecords ?? 0;
        if (state.totalFolder < PAGE_SIZE_DEFAULT) {
          state.pageContract = 1;
          state.contractsData = response.contracts.data;
        }
        state.currentFolderPath = response.folderPath ?? "";
        const listFolderPath = getListFolderFromPath(
          response.folderPath
        ) as any[];

        const lastFolderPath = listFolderPath[listFolderPath.length - 1];
        if (lastFolderPath) {
          state.currentFolderName = lastFolderPath["name"];
        }
        if (route.params.folderPath) {
          const lastPathElement = listFolderPath.pop();
          if (lastPathElement)
            state.breadScrumPaths.push({
              text: lastPathElement["name"] as string,
              to: `/template?folderId=${lastPathElement["id"]}&folderTitle=${lastPathElement["name"]}`,
            });
        } else {
          listFolderPath.map((path) => {
            state.breadScrumPaths.push({
              text: path["name"] as string,
              to: `/template?folderId=${path["id"]}&folderTitle=${path["name"]}`,
            });
          });
        }
      }
    };

    const getInitListBuiltinTemplate = async () => {
      const response = await loadBuiltinTemplate();
      if (response) {
        state.builtinTemplateList = response.data.data;
        state.totalBuiltin = response.data.pagination.totalRecords ?? 0;
      }
    };

    const onAddFolder = async ({ err, res }: { err: any; res: any }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        await reloadContractFolder({ reloadFolder: true });
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    const onSubmitRenameFolder = async ({
      err,
      res,
    }: {
      err: any;
      res: any;
    }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        await reloadContractFolder({ reloadFolder: true });
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    const onCancelManyTemplates = async () => {
      state.selectedTemplates = [];
      state.selectedFolders = [];
    };

    const onDeleteFolderContract = async ({
      err,
      res,
    }: {
      err: any;
      res: any;
    }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        if (state.confirmSelectedPopup === DeletePopupList.DELETE_FOLDER) {
          await reloadContractFolder({ reloadFolder: true });
          state.selectedFolders = state.selectedFolders.filter(
            (item) => item.id !== state.deleteFolderItem.id
          );
        } else if (
          state.confirmSelectedPopup === DeletePopupList.DELETE_CONTRACT || state.confirmSelectedPopup === DeletePopupList.DELETE_TEMPLATE
        ) {
          await reloadContractFolder({ reloadContract: true });
          state.selectedTemplates = state.selectedTemplates.filter(
            (item) => item.id !== state.deleteContractItem.id
          );
        } else if (
          state.confirmSelectedPopup === DeletePopupList.DELETE_MULTIPLE
        ) {
          await reloadContractFolder({
            reloadContract: true,
            reloadFolder: true,
          });
          state.selectedTemplates = [];
          state.selectedFolders = [];
        }
        showSuccessNotification(t("notification.deleteSuccess"));
      }
    };

    const onMoveContractFolder = async ({
      err,
      res,
    }: {
      err?: any;
      res?: any;
    }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        if (state.moveSelectedPopup === MovePopupList.MOVE_FOLDER) {
          await reloadContractFolder({ reloadFolder: true });
          state.selectedFolders = state.selectedFolders.filter(
            (item) => item.id !== state.deleteFolderItem.id
          );
        } else if (state.moveSelectedPopup === MovePopupList.MOVE_CONTRACT) {
          await reloadContractFolder({ reloadContract: true });
          state.selectedTemplates = state.selectedTemplates.filter(
            (item) => item.id !== state.deleteContractItem.id
          );
        } else if (state.moveSelectedPopup === MovePopupList.MOVE_MULTIPLE) {
          await reloadContractFolder({
            reloadContract: true,
            reloadFolder: true,
          });
          state.selectedTemplates = [];
          state.selectedFolders = [];
        }
        showSuccessNotification(t("notification.moveSuccess"));
      }
    };

    const onEditAccessFolder = async ({ err, res }: { err: any; res: any }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        await reloadContractFolder({ reloadFolder: true });
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    const onEditAccessContract = async ({
      err,
      res,
    }: {
      err: any;
      res: any;
    }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        await reloadContractFolder({ reloadContract: true });
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    let delay_template: ReturnType<typeof setTimeout>;
    const onSearchFolderContract = async (keyword: string) => {
      if (delay_template) clearTimeout(delay_template);
      delay_template = setTimeout(async () => {
        state.pageFolder = 1;
        state.pageContract = 0;
        state.totalFolder = -1;
        state.totalContract = -1;
        state.foldersData = [];
        state.contractsData = [];
        state.seachFolderContractKeyword = keyword;
        getInitListFolderContract();
      }, 300);
    };

    let delay_builtin: ReturnType<typeof setTimeout>;
    const onSearchBuiltinTemplate = (keyword: string) => {
      if (delay_builtin) clearTimeout(delay_builtin);
      delay_builtin = setTimeout(async () => {
        state.totalBuiltin = -1;
        state.pageBuiltin = 1;
        state.builtinTemplateList = [];
        state.searchBuiltinKeyword = keyword;
        getInitListBuiltinTemplate();
      }, 300);
    };

    const loadMoreOnScroll = async (onScroll: boolean) => {
      if (state.currentTabIndex == 0) {
        await loadMore("folder-contract", onScroll);
      } else {
        await loadMore("builtin", onScroll);
      }
    };

    const { createContractFromTemplate } = useContractFromTemplate();

    const clearSearch = () => {
      if (
        state.currentTabIndex == 0 &&
        state.seachFolderContractKeyword != ""
      ) {
        state.seachFolderContractKeyword = "";
        state.seachFolderContractKeywordTextField = "";
        onSearchFolderContract("");
      } else if (
        state.currentTabIndex == 1 &&
        state.searchBuiltinKeyword != ""
      ) {
        state.searchBuiltinKeyword = "";
        state.searchBuiltinKeywordTextField = "";
        onSearchBuiltinTemplate("");
      } else {
        return;
      }
    };

    const getDropInfo = () => {
      const isFolder = state.foldersData.some(
        (folder: any) => folder.id === state.draggedItem
      );
      // If dragged item in selected List => Will move many
      const isMoveMany = [
        ...state.selectedFolders,
        ...state.selectedTemplates,
      ].some((item: any) => item.id === state.draggedItem);

      // Change moveSelectedPopup state to run onSuccessError correct
      if (isMoveMany) {
        state.moveSelectedPopup = MovePopupList.MOVE_MULTIPLE;
      } else {
        if (isFolder) {
          state.moveSelectedPopup = MovePopupList.MOVE_FOLDER;
        } else {
          state.moveSelectedPopup = MovePopupList.MOVE_CONTRACT;
        }
      }
      return {
        isFolder,
        isMoveMany,
        selectedFolders: state.selectedFolders,
        selectedContracts: state.selectedTemplates,
        onSuccessError: onMoveContractFolder,
        isTemplateFolder: true
      };
    };

    onMounted(async () => {
      Promise.all([getInitListFolderContract(), getInitListBuiltinTemplate()]);
      if (props.isDeleteContractSuccerss == "true") {
        showSuccessNotification(t("notification.deleteSuccess"));
      }
      if (props.isEditTemplateSuccess == "true") {
        showSuccessNotification(t("notification.updateSuccess"));
      }
      // const scrollElement = document.querySelector(".left-part > .bottom-part");
      // scrollElement?.addEventListener("scroll", () => {
      //   if (state.currentTabIndex == 0) {
      //     loadMore("folder-contract", scrollElement);
      //   } else {
      //     loadMore("builtin", scrollElement);
      //   }
      // });

      const previousPath = getListFolderFromPath(
        (route.params.folderPath as string) ?? ""
      );
      previousPath.map((path) => {
        state.breadScrumPaths.push({
          id: path["id"] as string,
          text: path["name"] as string,
          to: `/template?folderId=${path["id"]}&folderTitle=${path["name"]}`,
        });
      });

      window.addEventListener("keydown", (e) => {
        if (e.key === "Shift" && !state.isShiftPress) {
          state.isShiftPress = true;
        }
      });

      window.addEventListener("keyup", (e) => {
        if (e.key === "Shift" && state.isShiftPress) {
          state.isShiftPress = false;
        }
      });
    });

    return {
      ...toRefs(state),
      isMobile,
      totalItems,
      folderId,
      loadBuiltinTemplate,
      loadMoreOnScroll,
      formatDate,
      createContractFromTemplate,
      onAddFolder,
      onSubmitRenameFolder,
      onEditAccessFolder,
      onEditAccessContract,
      onCancelManyTemplates,
      onDeleteFolderContract,
      onSearchFolderContract,
      onSearchBuiltinTemplate,
      getFilePathFromUrl,
      clearSearch,
      onMoveContractFolder,
      getDropInfo,
      onDragItemStart,
      onDragItemEnd,
      onDragItemEnter,
      onDropItem,
    };
  },
  computed: {
    enableMultipleDelete(): boolean {
      const canDeleteContract = !this.selectedTemplates.some(
        (item) => item.canDelete === false
      );
      const canDeleteFolder = !this.selectedFolders.some(
        (item) => item.canDelete === false
      );
      return canDeleteContract && canDeleteFolder;
    },
    enableMultipleMove(): boolean {
      const canMoveContract = !this.selectedTemplates.some(
        (item) => item.canUpdate === false
      );
      const canMoveFolder = !this.selectedFolders.some(
        (item) => item.canUpdate === false
      );
      return canMoveContract && canMoveFolder;
    },
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    handleDeselect(selectItem: any) {
      if (selectItem.isFolder) {
        this.selectedFolders = this.selectedFolders.filter(
          (item: any) => item.id !== selectItem.id
        );
      } else {
        this.selectedTemplates = this.selectedTemplates.filter(
          (item: any) => item.id !== selectItem.id
        );
      }
    },

    handleShiftSelect(selectItem: any, isSelected: boolean) {
      const selectedList = selectItem.isFolder
        ? this.selectedFolders
        : this.selectedTemplates;
      const index = selectedList.findIndex(
        (item: any) => item.id === selectItem.id
      );
      if (index === -1 && isSelected) {
        selectedList.push(selectItem);
        const [min, max] = this.getSelectedMaxMinIndex();
        const datas = [...this.foldersData, ...this.contractsData];
        const selectedIds = [
          ...this.selectedFolders,
          ...this.selectedTemplates,
        ].map((item) => item.id);
        for (let index = min; index <= max; index++) {
          if (!selectedIds.includes(datas[index].id)) {
            if (index < this.foldersData.length) {
              this.selectedFolders = [
                ...this.selectedFolders,
                datas[index] as FolderI,
              ];
            } else {
              this.selectedTemplates = [
                ...this.selectedTemplates,
                datas[index] as ContractI,
              ];
            }
          }
        }
      }
      if (index !== -1 && !isSelected) {
        this.handleDeselect(selectItem);
      }
    },

    handleSelect(selectItem: any, isSelected: boolean) {
      const selectedList = selectItem.isFolder
        ? this.selectedFolders
        : this.selectedTemplates;
      const index = selectedList.findIndex(
        (item: any) => item.id === selectItem.id
      );
      if (index === -1 && isSelected) {
        selectedList.push(selectItem);
      }
      if (index !== -1 && !isSelected) {
        this.handleDeselect(selectItem);
      }
    },

    onSelect(item: any, isSelected: boolean) {
      const totalSelect =
        this.selectedFolders.length + this.selectedTemplates.length;
      if (this.isShiftPress && totalSelect > 0) {
        this.handleShiftSelect(item, isSelected);
      } else {
        this.handleSelect(item, isSelected);
      }
    },

    getSelectedMaxMinIndex(): any[] {
      const selectedIds = [
        ...this.selectedFolders,
        ...this.selectedTemplates,
      ].map((item) => item.id);
      const datas = [...this.foldersData, ...this.contractsData];
      let result: any[] = []; // [Min,Max]
      let firstExact = false;
      datas.forEach((item, index) => {
        if (selectedIds.includes(item.id)) {
          result = firstExact ? [result[0], index] : [index, index];
          firstExact = true;
        }
      });
      return result;
    },

    onShowMenuOption(id: string) {
      this.showMenuOptionId = this.showMenuOptionId === id ? "" : id;
    },

    uploadTemplate() {
      const { folderId } = this.$route.query;
      if (this.currentFolderName) {
        this.$router.push({
          name: "TemplateFileAndInfo",
          query: {
            folderId: folderId,
            folderName: this.currentFolderName,
          },
        });
      } else {
        this.$router.push({
          name: "TemplateFileAndInfo",
          query: {
            folderId: folderId,
          },
        });
      }
    },

    editTemplate(id: string) {
      this.$router.push({ name: "TemplateFileAndInfo", params: { id } });
    },

    handleRenameFolder(folderItem: FolderI) {
      this.renameFolderItem = new Folder(folderItem);
      this.renameSelectedPopup = RenamePopupList.RENAME_FOLDER;
      this.setPopup("FolderRename");
    },

    handleEditAccessFolder(folderItem: FolderI): void {
      this.editAccessFolderItem = new Folder(folderItem);
      this.setPopup("FolderEditAccess");
    },

    handleEditAccessContract(contractItem: ContractI): void {
      this.editAccessContractItem = new Contract(contractItem);
      this.setPopup("ContractEditAccess");
    },

    handleMoveFolder(folderItem: FolderI): void {
      this.moveFolderItem = new Folder(folderItem);
      this.showMenuOptionId = "";
      this.moveSelectedPopup = MovePopupList.MOVE_FOLDER;
      this.setPopup("FolderContractMove");
    },

    handleMoveContract(contractItem: ContractI): void {
      this.moveContractItem = new Contract(contractItem);
      this.showMenuOptionId = "";
      this.moveSelectedPopup = MovePopupList.MOVE_CONTRACT;
      this.setPopup("FolderContractMove");
    },

    handleMoveMultipleContract() {
      this.moveSelectedPopup = MovePopupList.MOVE_MULTIPLE;
      this.setPopup("FolderContractMove");
    },

    handleDeleteFolder(folderItem: FolderI): void {
      this.deleteFolderItem = new Folder(folderItem);
      this.confirmSelectedPopup = DeletePopupList.DELETE_FOLDER;
      this.setPopup("ConfirmDelete");
    },

    handleDeleteContract(contractItem: ContractI): void {
      this.deleteContractItem = new Contract(contractItem);
      this.confirmSelectedPopup = DeletePopupList.DELETE_TEMPLATE;
      this.setPopup("ConfirmDelete");
    },

    handleDeleteMultiple() {
      this.confirmSelectedPopup = DeletePopupList.DELETE_MULTIPLE;
      this.setPopup("ConfirmDelete");
    },

    download(url: string) {
      let downloadPoint = document.createElement("a");
      downloadPoint.href = url;
      downloadPoint.target = "_blank";
      downloadPoint.click();
    },

    hideMenuOption(e: any): void {
      if (e.target.className != "icon selected" && e.target.alt != "more") {
        this.showMenuOptionId = "";
      }
    },
  },
});
