
import { defineComponent, reactive, watch, inject, toRefs } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { formatDate } from "@/lib/utility/common";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { hasPermission } from "@/lib/utility/permission";

export default defineComponent({
  name: "TemplateItem",
  components: {
    TextButton,
    FlatButton,
    MenuOptionDropdown,
  },
  props: {
    contract: { type: Object, required: true },
    showMenuOptionId: { type: String, default: "" },
    selectedContractList: { type: Array },
    createContractFromTemplate: { type: Function, required: true },
    isBuiltInTemplate: { type: Boolean, default: false },
    currentTabIndex: { type: Number }
  },
  emits: [
    "onSelect",
    "onMoveContract",
    "onDeleteContract",
    "onEditAccess",
    "onEditContract",
    "onShowMenuOption",
  ],
  setup(props) {
    const state = reactive({
      isHover: false,
      isSelected: false,
    });

    const isMobile = inject("isMobile");
    const isPermitted = hasPermission(props.contract.role)

    watch(
      () => props.selectedContractList,
      (selectedContractList) => {
        state.isSelected =
          selectedContractList?.some(
            (item: any) => item.id === props.contract.id
          ) ?? false;
      },
      {
        immediate: true,
      }
    );

    const download = (url: string) => {
      let downloadPoint = document.createElement("a");
      downloadPoint.href = url;
      downloadPoint.target = "_blank";
      downloadPoint.click();
    };

    return {
      ...toRefs(state),
      download,
      formatDate,
      getFilePathFromUrl,
      isMobile,
    };
  },
  methods: {
    handleSelectContract(item: any) {
      this.$emit("onSelect", item, this.isSelected);
    },
    handleClickIcon(item: any) {
      this.isSelected = true;
      this.handleSelectContract(item);
    },
    handleShowMenuOption(id: string) {
      this.$emit("onShowMenuOption", id);
    },
    clickTitle(id: string) {
      if(this.currentTabIndex == 0) {
        this.$emit('onEditContract', id);
      }
    },
  },
});
